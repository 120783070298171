var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Row',[_c('Col',{attrs:{"span":"12"}},[_c('Card',{attrs:{"bordered":false}},[_c('p',{attrs:{"slot":"title"},slot:"title"},[_vm._v("流转记录")]),_c('Table',{attrs:{"columns":_vm.forwardColums,"data":_vm.forwards,"max-height":"500"},scopedSlots:_vm._u([{key:"state",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.worderState(row)))])]}},{key:"sender",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.sender.username))])]}},{key:"receiver",fn:function(ref){
var row = ref.row;
return [_vm._l((row.receivers),function(recvInfo){return [_c('span',{key:recvInfo._index},[_vm._v(_vm._s(recvInfo.user.username)+",")])]})]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.created_at))])]}},{key:"comment",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.forward))])]}}])})],1)],1),_c('Col',{attrs:{"span":"12"}},[_c('Card',{staticStyle:{"min-height":"580px"},attrs:{"bordered":false}},[_c('p',{attrs:{"slot":"title"},slot:"title"},[_vm._v("工单内容")]),_c('p',[_vm._v(" "+_vm._s(_vm.row.detail)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }