<template>
  <Row>
    <Col span="12">
      <Card :bordered="false">
        <p slot="title">流转记录</p>
        <Table :columns="forwardColums" :data="forwards" max-height="500">
          <template slot-scope="{ row }" slot="state">
            <span>{{worderState(row)}}</span>
          </template>
          <template slot-scope="{ row }" slot="sender">
            <span>{{ row.sender.username }}</span>
          </template>
          <template slot-scope="{ row }" slot="receiver">
            <template v-for="recvInfo in row.receivers">
              <span :key="recvInfo._index">{{recvInfo.user.username}},</span>
            </template>
          </template>
          <template slot-scope="{ row }" slot="time">
            <span>{{ row.created_at }}</span>
          </template>
          <template slot-scope="{ row }" slot="comment">
            <span>{{ row.forward }}</span>
          </template>
        </Table>
      </Card>
    </Col>
    <Col span="12">
      <Card :bordered="false" style="min-height: 580px">
        <p slot="title">工单内容</p>
        <p>
          {{ row.detail }}
        </p>
      </Card>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "WorderTableExpend",
  props: {
    row: Object
  },
  data() {
    return {
      forwardColums: [
        {
          title: '工单状态',
          width: 149,
          slot: 'state'
        },
        {
          title: '转发人',
          width: 100,
          slot: 'sender'
        },
        {
          title: '受理人',
          width: 205,
          slot: 'receiver'
        },
        {
          title: '备 注',
          slot: 'comment'
        },
        {
          title: '时 间',
          width: 169,
          slot: 'time'
        },
      ],
    }
  },
  methods: {
    worderState({state}) {
      switch (state) {
        case 1:
          return "新建"
        case 2:
          return "审核中"
        case 3:
          return "处理中"
        case 4:
          return "已结单"
      }
    },
  },
  computed: {
    forwards() {
      return this.row.forwards;
    }
  }
}
</script>

<style scoped>

</style>